<template>
  <div>
    <IbTabs />
    <b-card>
      <b-row>
        <b-col

          sm="12"
        >

          <h2 class="text-success">
            Web banners
          </h2>
          <p class="mt-3">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dicta fugiat explicabo vel libero eum nam velit, id harum, accusantium temporibus blanditiis quisquam et deserunt, nulla minima rerum aut quidem officiis.
          </p>
          <b-row class="mt-5">
            <b-col>
              <label
                for="Accounts"
                class="text-black font-weight-bolder h5 mb-1"
              >{{ $t('accounts') }}</label>
              <b-form-select
                :options="options"
                class="mb-3"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
              />

            </b-col>
            <b-col>
              <label
                for="Choose your language"
                class="text-black font-weight-bolder h5 mb-1"
              >{{ $t('choose-language') }}</label>
              <b-form-select
                :options="options"
                class="mb-3"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
              />

            </b-col>

            <b-col>
              <label
                for="Size"
                class="text-black font-weight-bolder h5 mb-1"
              >{{ $t('size') }}</label>
              <b-form-select
                :options="options"
                class="mb-3"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
              />
            </b-col>
            <b-col>
              <label
                for="Type of banner"
                class="text-black font-weight-bolder h5 mb-1"
              >{{ $t('type-banner') }}</label>
              <b-form-select
                :options="options"
                class="mb-3"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormSelect,
} from 'bootstrap-vue'
import IbTabs from './components/ibTabs.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormSelect,
    IbTabs,
  },
  data() {
    return {
      options: [
        { item: 'A', name: 'Option A' },
        { item: 'B', name: 'Option B' },
        { item: 'D', name: 'Option C', notEnabled: true },
        { item: { d: 1 }, name: 'Option D' },
      ],
    }
  },
}
</script>
